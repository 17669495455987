@import './fonts.css';

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: #063056;
  max-width: 1600px;
  min-width: 1440px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.serverErrror {
  margin-bottom: 0 !important;
  color: #EB224D;
}

.error {
  color: #EB224D; 
}

a {
  color: #fff !important;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Buttons */

.page-arrow {
  border: 1px solid #C8D2DF !important;
  border-radius: 3px !important;
  color: #0F4C82 !important;
  padding: 5px !important;
  margin: 0px 10px !important;
}

.MuiIconButton-root.Mui-disabled {
  opacity: 0.5;
}

.filterContainer {
  position: relative;
  display: inline-block;
}

.clearButton {
    background-color: #0F4C82 !important;
    color: #fff !important;
    border-radius: 30px !important;
    padding: 8px 25px !important;
    max-width: 300px;
    overflow: hidden;
    height: 42px;
    line-height: 42px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.clearButton:hover {
  color: #0F4C82 !important;
  background: #fff !important;
}

.clearButton .MuiButton-label {
  overflow: hidden;
  height: 42px;
  line-height: 42px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.logOutButton .MuiButton-label {
  color: #fff;
  transition: color 0.3s ease;
  display: flex;
  gap: 15px;
  flex-direction: row-reverse;
}

.reportsButton .MuiButton-label {
  color: #fff;
  transition: color 0.3s ease;
  display: flex;
  gap: 15px;
}

#time-zone-menu .MuiPopover-paper {
  top: 90px !important;
}

.reportsButton .MuiButton-iconSizeMedium > *:first-child {
  font-size: 32px;
}

.reportsButton:hover .MuiButton-label,
.logOutButton:hover .MuiButton-label {
  color: #ECA945;
}

.reportsIcon {
  font-size: 40px !important;
}

.reportsText {
  font-size: 20px !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #f8a322 !important;
}

.MuiButton-contained.Mui-disabled {
  background-color: #90A5BE !important;
  color: #fff !important;
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.menu-active {
  color: #ECA945;
}

.removeFilterIcon {
  background: #666;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
}

thead tr th {
  color: #0F4C82  !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 18px !important;
  text-transform: uppercase !important;
  padding: 12px !important;
}

tbody tr td {
  color: #231F20;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.16px;
  padding: 11px !important;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #fff !important; 
}

.MuiOutlinedInput-inputAdornedStart {
  color: #6D6E70;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.MuiInputLabel-root.Mui-focused {
  color: #6D6E70 !important;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.018px;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #6D6E70 !important;
}

.MuiDialogActions-root {
  justify-content: space-between !important;
  padding: 10px 15px 20px 15px;
}

.MuiDialogActions-root .MuiButton-textPrimary {
  color: #0F4C82 !important;
  font-weight: 700;
}

.flex-row {
  display: flex;
}

.chatModal {
  border-radius: 30px;
}

.chatModalClose {
  position: absolute !important;
  right: 10px;
  top: 10px;
  background: #777 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 0px !important;
}

.chatModalPetInfo {
  min-width: 670px;
  margin-bottom: 15px;
}
.chatModalTitle {
  padding-bottom: 0 !important;
}

.chatModalUserType {
  text-transform: uppercase;
}

.chatBubbble-label {
  color: #777;
  margin: 0 !important;
  font-size: 14px;
}

.chatBubbble-text {
  color: #222;
  margin: 10px 0 10px 0 !important;
  font-size: 13px;
}

.chatModalInfoRow {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  font-size: 12px;
  color: #666;
  letter-spacing: 0.16px;
  font-weight: 400;
}

.chatModalPdfButton {
  color: #0F4C82 !important;
}

.chatModalBody {
  border-radius: 2px;
  border: 1px solid #CCC;
  padding: 0px;
  overflow: auto;
  max-height: 670px;
  min-height: 670px;
}

.chatModalBody .pageLoaderContainer {
   background-color:  #fff !important;
}

.pageLoaderContainerSmall {
  background-color:  #fff !important;
}

.pageLoaderContainerSmall .loaderCircleSmall {
  width: 110px;
  height: 110px;
  margin: 0;
}

.statsContainer {
  display: flex;
  justify-content: left;
  width: 100%;
  position: relative;
}

.statsContainer .pageLoaderContainerSmall {
  background-color:  #fff !important;
  margin-left: -80px;
  margin-top: 20px;
  width: 100%;
}


.pageLoaderContainerSmall .pageLoaderImage {
  text-align: center;
}

.pageLoaderContainerSmall .pageLoaderText {
  color: #ECA945;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal !important;
  padding-top: 10px;
  text-align: center;
}

.reportsModal .pageLoaderContainer {
  background-color:  #fff !important;
  margin-bottom: 200px;
}

.chatModalContent {
  padding: 0 10px;
  overflow: auto;
}

.MuiDialogContent-root {
  min-width: 380px;
}

.chatBubble-Bot {
  background: #F4E6DE;
  border: 1px solid #F4E6DE;
  margin-left: auto;
  border-radius: 10px 10px 0 10px;
}

.chatFileContainer img {
  border-radius: 5px;
}

.chatFileContainer figure {
  margin: 0;
}

.chatFileContainer figcaption {
  font-size: 11px;
  color: #555;
}

.chatBubble-Vet {
  background: rgba(137, 213, 245, 0.50);
  border: 1px solid rgba(137, 213, 245, 0.80);
  margin-left: auto;
  border-radius: 10px 10px 0 10px;
}

.chatBubble-PetOwner {
  background: #E7EDF3;
  border: 1px solid #C8D2DF;
  border-radius: 10px 10px 10px 0;
}

.chatBubble-Operator {
  background: #F0F0F0;
  border: 1px solid #ccc;
  margin-left: auto;
  border-radius: 10px 10px 0px 10px;
}

.chatBubble-Entry {
  background: #DCE6F2;
  border: 1px solid #999;
  border-radius: 0px 0px 0px 0;
  max-width: 100% !important;
}

.chatBubble-label {
  padding: 0;
  color: #777;
  font-size: 12px;
  margin: 0;
}
.chatBubble-text p {
  padding: 0;
  font-size: 13px;
}

.postType-CreatedOn {
  text-align: center;
  margin: 0px 0 15px 0;
  font-size: 12px !important;
  color: #0F4C82;
}

.postType-Join, .postType-Leave, .postType-ChangeQueue, .postType-VetConcluded {
  text-align: center;
  margin: 0px 0 0px 0;
  font-size: 10px !important;
  color: #999;
}

.Leave-Vet {
  color: #EB224D;
}

.Join-Operator,
.Join-PetOwner,
.Join-Bot,
.Join-Vet {
  color: #999;
}
.VetConcluded-Operator,
.Leave-Operator,
.Leave-PetOwner,
.Leave-Bot {
  color: #EB224D;
}

.bar:after {
  content: '';
  width: 10px;
  height: 22px;
  background-image: url('./images/arrow_tip.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: -10px;
  background-position: right;
}

.bar:before {
  content: '';
  width: 10px;
  height: 22px;
  background-image: url('./images/arrow_end.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: -10px;
  background-position: left;
}
.color-red {
    background-color: #B05B34;
}
.color-yellow {
    background-color: #ECA945;
}
.color-yellow:after {
  background-image: url('./images/arrow_tip_yellow.svg');
}
.color-yellow:before {
  background-image: url('./images/arrow_end_yellow.svg');
}

.color-blue:after {
  background-image: url('./images/arrow_tip_blue.svg');
}
.color-blue:before {
  background-image: url('./images/arrow_end_blue.svg');
}

.color-pink:after {
  background-image: url('./images/arrow_tip_pink.svg');
}
.color-pink:before {
  background-image: url('./images/arrow_end_pink.svg');
}
.color-blue {
    background-color: #063056;
}
.color-lightblue {
    background-color: #90A5BE;
}
.color-pink {
    background-color: #F6D3C5;
}

.color-pink span {
  color: #000 !important;
}
.loading-grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 10px;
}

.loading-row {
  display: flex;
  gap: 10px;
}

.loading-cell {
  width: auto;
  min-width: 120px; 
  height: 20px;
  background: linear-gradient(90deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 400% 100%;
  animation: wave 3.5s infinite;
  border: 10px solid #fff !important;
  border-radius: 20px;
}

.loading-cell-11 {
  width: auto;
  min-width: auto; 
  height: 20px;
  background: linear-gradient(90deg, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 400% 100%;
  animation: wave 3.5s infinite;
  border: 10px solid #fff !important;
  border-radius: 20px;
}

.no-data-small-message {
  height: 115px;
  display: flex; 
  text-transform: uppercase;
  color: #626365;
  font-size: 24px !important;
  font-weight: 500 !important;
  align-items: end;
  justify-content: left;
  padding-left: 15px;
}

.no-data-message {
  height: 600px;
  display: flex; 
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #626365;
  font-size: 36px !important;
  font-weight: 300 !important;
}
.MuiFormHelperText-root {
  color: #000 !important;
  font-size: 13px !important;
  padding-top: 20px;
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336 !important;
}

@keyframes wave {
  0%,
  100% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 0;
  }
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to { 
      transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
  }
  to { 
      -webkit-transform: rotate(360deg);
  }
}

.pageLoaderContainer {
  background-color: #063056 !important;
  text-align: center;
  height: calc(100% - 400px);
}

.loaderCircleSmall {
  display: inline-block;
  width: 230px;
  height: 230px;
  margin: 230px auto 0;
  background-image: url('./images/loaderCircleBig.svg');
  background-size: contain;
  border-radius: 50%;
 -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name:             rotate; 
  -webkit-animation-duration:         2.0s; 
  -webkit-animation-iteration-count:  infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name:             rotate; 
  animation-duration:         2.0s; 
  animation-iteration-count:  infinite;
  animation-timing-function: linear; 
}

.pageLoaderText {
  color: #ECA945;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal !important;
  padding-top: 20px;
}

/* Check box and radio colors adjustments */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #0F4C82 !important;
}

/* Dropdown Menu custom styling */

.dropdownMenu .MuiMenu-paper {
  top: 90px !important;
}

.MuiSelect-nativeInput {
  opacity: 1 !important;
  border: 0 !important;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 1px solid grey !important;
  line-height: 2;
}

.clear-button {
  background-color: #fff !important;
  color: #0F4C82 !important;
  border: 1px solid #0F4C82 !important;
  border-radius: 30px !important;
  margin-left: 20px !important;
  padding: 8px 25px !important;
  max-width: 300px;
  overflow: hidden;
  height: 42px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.023px;
  text-transform: uppercase;
}

.save-button {
  background-color: #0F4C82 !important;
  color: #fff !important;
  border-radius: 30px !important;
  margin-left: 20px !important;
  padding: 8px 25px !important;
  max-width: 300px;
  overflow: hidden;
  height: 42px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.023px;
  text-transform: uppercase;
}

.filterButton{   
  color: #0F4C82;
  background-color: #DBE2EA;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 14px;
  padding: 0 10px;
  margin-left: 20px;
}

.notification {
  color: #063056;
}

.selectedItemsGroup {
  padding: 20px 10px;
  border: 2px solid #000;
  border-radius: 0px;
  height: 500px;
  width: calc(100% - 25px);
  overflow: auto;
}

.selectedItemsGroupTitle {
  position: absolute;
  top: -19px;
  background: #fff;
  padding: 5px 10px;
}

.searchField {
  border: 1px solid #DBE2EA !important;
  border-radius: 30px;
  width: 100%;
  margin: 0 0 20px 0 !important;
}

.reportContainer {
  padding-left: 0;
  display: flex;
  min-width: 100%;
  gap: 30px;
  width: 900px;
  min-height: 500px;
}

.reportContainer .searchField .MuiOutlinedInput-adornedStart {
    padding-left: 5px !important;
    border-radius: 50% !important;
}

.reportDialogFlexRow {
  width: 50%;
  position: relative;
  margin-top: 20px;
}

.MuiDialog-paperWidthSm {
  max-width: 1200px !important;
}

.checkboxScrollContainer {
  max-height: 500px;
  overflow: auto;
  display: grid !important;
}


