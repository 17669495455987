
@font-face {
    font-family: 'Kreon';
    src: local('Kreon'), url('../public/fonts/Kreon-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KreonSemiBold';
    src: local('Kreon'), url('../public/fonts/Kreon-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}